import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import MetaTags from "react-meta-tags"

const NotFoundPage = () => (
  <Layout className="not-found-page">
    <MetaTags>
      <title>Page not found</title>
    </MetaTags>
    <Link to="/">
      <img src="/assets/404.svg" alt="" className="w-full" />
    </Link>
  </Layout>
)

export default NotFoundPage
